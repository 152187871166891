import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FirebaseService } from './firebase.service';
import { BehaviorSubject, Observable, Subject, combineLatest, first, from, map, of, switchMap, take, tap } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { CustomerTrasformerService } from './customer-trasformer.service';
import { User } from '../models/user.modal';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { StripeService } from './stripe.service';
import { Customer } from '../models/customer.modal';
import { EventTrasformerService } from './event-trasformer.service';
// import { CheckoutService } from './checkout.service';
import { BrandKit } from '../models/brandKit.modal';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    allCustomers = [];
    saCustomerName$ = new BehaviorSubject('');
    $connectedEloqua = new Subject();
    isOwner!: boolean;
    currency: any;
    // userId = localStorage ? localStorage.getItem('user_id') || this.Cookies.get('user_id') : '';
    // customerId = localStorage ? localStorage.getItem('customer_id') || this.Cookies.get('customer_id') : '';
    speakerList: any = [];
    attendeeList: any = [];
    constructor(
        private db: AngularFireDatabase,
        private Firebase: FirebaseService,
        private http: HttpClient,
        private Utilities: UtilityService,
        private customerTransformer: CustomerTrasformerService,
        private Cookies: CookieService,
        private stripe: StripeService,
        private utilityService: UtilityService
    ) {}

    // getAllCustomers() {
    //     // const customersRef = `/customers`
    //     // return this.Firebase.getByRef(customersRef).pipe(first(), map((res: any) => {
    //     //     const retArr: any = [];
    //     //     if (res) {
    //     //         _.forOwn(res, (val, key) => {
    //     //             retArr.push({ ...val, id: key })
    //     //         })
    //     //     }
    //     //     return retArr;
    //     // }))
    //     if (!this.allCustomers.length) {
    //         return (
    //             this.http
    //                 .get(`${environment.fcfApiUrl}/v2api/customers-list`)
    //                 // return this.http.get(`http://s/dev-hellocrowd/us-central1/api/customers-list`)
    //                 .pipe(
    //                     tap((data: any) => {
    //                         this.allCustomers = data;
    //                     })
    //                 )
    //         );
    //     } else {
    //         return of(this.allCustomers);
    //     }
    // }

    checkPaidTickets(customerId: string, eventId: string) {
        return new Promise((resolve) => {
            const ref = `customers/${customerId}/eventsData/${eventId}/eventSetup/tickets`;
            this.Firebase.getByRefWhere(ref, 'type', 'PAID').then((data) => {
                resolve(Boolean(data));
            });
        });
    }

    getBankDetails(customerId = '') {
        const ref = `customers/${customerId}/bankDetails`;
        return this.Firebase.getByRef(ref);
    }

    getAllCustomers() {
        // const customersRef = `/customers`
        // return this.Firebase.getByRef(customersRef).pipe(first(), map((res: any) => {
        //     const retArr: any = [];
        //     if (res) {
        //         _.forOwn(res, (val, key) => {
        //             retArr.push({ ...val, id: key })
        //         })
        //     }
        //     return retArr;
        // }))
        if (!this.allCustomers.length) {
            return (
                this.http
                    .get(`${environment.fcfApiUrl}/v2api/customers-list`)
                    // return this.http.get(`http://s/dev-hellocrowd/us-central1/api/customers-list`)
                    .pipe(
                        tap((data: any) => {
                            this.allCustomers = data;
                        })
                    )
            );
        } else {
            return of(this.allCustomers);
        }
    }

    getEvent(customerId: string, eventId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/events/${eventId}`).pipe(
            take(1),
            map((value: any) => {
                // const privateData = _.pick(value, 'private_data');
                // const publicData = _.omit(value, ['private_data', 'app_id']);
                // const event: any = _.merge(publicData, privateData.private_data);
                // event.id = eventId;

                // return event;

                return value
                    ? this.customerTransformer.transformSingle(this.customerTransformer.transform(value))
                    : null;
            })
        );
    }

    getSubscription(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/subscription`);
    }

    getStripeId(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/stripeId`);
    }
    getDefaultTimeZone(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultTimeZone`);
    }

    getDefaultCurrency(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultCurrency`) as Observable<string>;
    }
    // updateEventFb(data: any, customerId: string, eventId: string) {
    //     const eventData = this.customerTransformer.prepareData(data);

    //     return this.updateEventData(eventData, data, customerId, eventId);
    // }

    // updateEventData(eventData: any, data: any, customerId: string, eventId: string) {
    //     const listRef = `customers/${customerId}/events/${eventId}`;
    //     return new Promise((resolve, reject) => {
    //         this.db
    //             .object(listRef)
    //             .update(eventData)
    //             .then(() => {
    //                 resolve({ data: data });
    //             })
    //             .catch((e) => {
    //                 console.log('ERROR', Error);
    //                 reject(e);
    //             });
    //     });
    // }

    // updateEventDate(data: any, customerId: string, eventId: string) {
    //     const eventData = this.eventTransformer.prepareEventDate(data);
    //     return this.updateEventData(eventData, data, customerId, eventId);
    // }

    getCustomer(customerId: string) {
        const ref = `customers/${customerId}`;
        return this.Firebase.getByRef(ref) as Observable<any>;
    }

    // updateCustomer(data: any, customerId: string) {
    //     return new Promise((resolve, reject) => {
    //         // if(
    //         const ref = `customers/${customerId}`;
    //         this.Firebase.updateByRef(ref, data)
    //             .then(() => resolve(true))
    //             .catch((erorr) => {
    //                 console.log('error', erorr);
    //                 reject(erorr);
    //             });
    //     });
    // }

    // createEvent(customerId: string, userId: string, time_zone: string) {
    //     // const user =
    //     return new Promise((resolve, reject) => {
    //         this.Firebase.getByRef(`customers/${customerId}/brandKit`)
    //             .pipe(first())
    //             .subscribe((brandKit: BrandKit) => {
    //                 const prepareData: any = {
    //                     user_id: `user${userId}`,
    //                     colour_scheme: '#3748ac',
    //                     date_format: 'd/m/Y',
    //                     time_format: 'H:i',
    //                     // created_at: Math.floor(Date.now() / 1000),
    //                     created_at: moment.utc().toISOString(),
    //                     time_zone: time_zone,
    //                     status: 'DRAFT',
    //                 };
    //                 if (brandKit) {
    //                     prepareData.app_icon = brandKit.icon || null;
    //                     prepareData.colour_scheme = brandKit.colours.button || '#3748ac';
    //                     prepareData.colours = brandKit.colours || null;
    //                     prepareData.bodyFonts = brandKit.bodyFonts || null;
    //                     prepareData.headerFonts = brandKit.headerFonts || null;
    //                     prepareData.style = brandKit.style || null;
    //                     prepareData.lightCustomerLogo = brandKit.lightCustomerLogo || null;
    //                     prepareData.darkCustomerLogo = brandKit.darkCustomerLogo || null;
    //                 }
    //                 this._generateEventId(customerId).then((key) => {
    //                     console.log(prepareData.created_at);
    //                     this.Firebase.setByRef(`customers/${customerId}/events/${key}`, prepareData)
    //                         .then(() => {
    //                             resolve(key);
    //                         })
    //                         .catch((error) => {
    //                             console.log('Error', error);
    //                             reject(error);
    //                         });
    //                 });
    //             });
    //     });
    // }

    // _generateEventId(customerId: string) {
    //     console.log('appId', customerId);
    //     return new Promise((resolve) => {
    //         this.getAllEventIds(customerId).then((ids) => {
    //             let eventId = this.Utilities.generateUID(5);
    //             while (ids.indexOf(eventId) !== -1) {
    //                 eventId = this.Utilities.generateUID(5);
    //             }
    //             resolve(eventId);
    //         });
    //         // const url = `${environment.firebaseConfig.databaseURL}/customers/${customerId}/events.json?auth=${environment.firebaseConfig.secret}&shallow=true`;
    //         // this.http.get(url).subscribe((items: any) => {
    //         //     const ids = Object.keys(items);
    //         // });
    //     });
    // }

    // getAllEventIds(customerId: string) {
    //     return new Promise((resolve) => {
    //         // if()
    //         const url = `${environment.firebaseConfig.databaseURL}/customers/${customerId}/events.json?auth=${environment.firebaseConfig.secret}&shallow=true`;
    //         this.http.get(url).subscribe((items: any) => {
    //             if (items) {
    //                 const ids = Object.keys(items);
    //                 resolve(ids);
    //             }
    //             resolve([]);
    //         });
    //     }) as Promise<string[]>;
    // }

    // convertToCustomerEvent(user: User) {
    //     return new Promise((resolve, reject) => {
    //         console.log('convertToCustomerEvent', user);
    //         const promise = [];
    //         if (_.keys(user.app) && user.app) {
    //             const appIds = _.keys(user.app);
    //             if (!appIds.includes('HELLOCROWD')) {
    //                 appIds.push('HELLOCROWD');
    //             }

    //             for (const id of appIds) {
    //                 promise.push(this.Firebase.getEventByUser(id, user.id));
    //             }

    //             Promise.all(promise).then((events) => {
    //                 resolve(events);
    //             });
    //         } else {
    //             resolve([]);
    //         }
    //     });
    // }

    // copyEventToCustomer(appId: any, event: any) {
    //     return new Promise((resolve, reject) => {
    //         const customerId = localStorage.getItem('customer_id') || this.Cookies.get('customer_id');
    //         const prepareData = this.customerTransformer.prepareData(event);
    //         const eventId = event.id;
    //         if (appId) {
    //             prepareData.app_id = appId;
    //         }

    //         prepareData.title = this._createEventTitle(event.title);
    //         prepareData.status = 'DRAFT';

    //         this.Firebase.setByRef(`customers/${customerId}/events/${eventId}`, prepareData)
    //             .then(() => {
    //                 if (appId === 'HELLOCROWD') {
    //                     this.Firebase.updateByRef(`customers/${customerId}/hellocrowdEvents`, {
    //                         [`${eventId}`]: true,
    //                     });
    //                 }
    //                 resolve(eventId);
    //             })
    //             .catch((error) => {
    //                 console.log('Error', error);
    //                 reject(error);
    //             });
    //     });
    // }

    // createUpdateStripeCustomer() {
    //     // return new Promise((resolve) => {
    //     const customerId = localStorage.getItem('customer_id') || this.Cookies.get('customer_id');
    //     return this.getCustomer(customerId).pipe(
    //         take(1),
    //         switchMap((customer: any): any => {
    //             const teamMembersIds = _.keys(customer.teamMembers);
    //             const companyOwner = teamMembersIds.find((key) => customer.teamMembers[key].toLowerCase() === 'owner');
    //             console.log('copanyOwner', companyOwner);
    //             return this.Firebase.getByRef(`users/${companyOwner}`).pipe(
    //                 switchMap((user: any) => {
    //                     const prepareData: any = {
    //                         first_name: user.first_name || '',
    //                         last_name: user.last_name || '',
    //                         email: user.email,
    //                         company_city: customer.address?.addressLocality || '',
    //                         company_country_code: customer.address?.addressCountry || '',
    //                         company_address1: customer.address?.streetAddress || '',
    //                         company_address2: customer.address?.addressLine2 || '',
    //                         company_zip: customer.address?.postalCode || '',
    //                         company_province: customer.address?.addressRegion || '',
    //                         id: companyOwner,
    //                         companyName: customer.companyName || user.first_name + ' ' + user.last_name || '',
    //                         currency: customer.defaultCurrency || 'usd',
    //                         time_zone: customer.defaultTimeZone || 'UTC',
    //                     };

    //                     if (user.payment_profile?.customer_id || customer.stripeId) {
    //                         prepareData.customer = customer.stripeId || user.payment_profile?.customer_id;
    //                     }

    //                     return this.stripe.getUpdateCustomer(prepareData).pipe(
    //                         map((data) => {
    //                             return data;
    //                         })
    //                     );
    //                 })
    //             );
    //         })
    //     );
    //     // });
    // }

    // getAnalyticsData(customerId: string, eventId: string, enabledTools: string[], appId = '') {
    //     return new Promise((resolve, reject) => {
    //         this.getEventCapacity(customerId, eventId, appId).then((eventCapacity) => {
    //             const promises: any = [];
    //             if (enabledTools.includes('Tickets & Reg')) {
    //                 promises.push(this.getTicketsReport(customerId, eventId));
    //             }
    //             if (enabledTools.includes('Event Feedback')) {
    //                 promises.push(this.getEventFeedbackReport(customerId, eventId));
    //             }
    //             if (enabledTools.includes('Causes')) {
    //                 promises.push(this.getDonationsReport(customerId, eventId));
    //             }
    //             if (enabledTools.includes('Mobile App')) {
    //                 promises.push(this.getAppDownloadReport(appId, eventId));
    //             }
    //             Promise.all(promises).then((res) => {
    //                 const retObj: any = { eventCapacity };
    //                 _.forEach(res, (report) => {
    //                     const key = Object.keys(report)[0];
    //                     retObj[key] = report[key];
    //                 });
    //                 resolve(retObj);
    //             });
    //         });
    //     });
    // }

    // getEventCapacity(customerId: string, eventId: string, appId: string) {
    //     return new Promise((resolve, reject) => {
    //         const eventCapacityRef = `customers/${customerId}/events/${eventId}/eventCapacity`;

    //         this.Firebase.getByRef(eventCapacityRef)
    //             .pipe(first())
    //             .subscribe((eventCapacity) => {
    //                 // console.log("EVENT CAPACITY IS", eventCapacity);

    //                 resolve(eventCapacity || 0);

    //                 //  else {
    //                 //     const ref = `customers/${customerId}/eventsData/${eventId}/invitees`;
    //                 //     const appAttendees = `/apps/${appId}/public/events_data/${eventId}/attendees`;
    //                 //     combineLatest([
    //                 //         this.Firebase.getByRef(ref),
    //                 //         appId ? this.Firebase.getByRef(appAttendees) : of(null),
    //                 //     ])
    //                 //         .pipe(first())
    //                 //         .subscribe((res: any) => {
    //                 //             let totalInviteeAttendee = 0;
    //                 //             let totalAppAttendee = 0;
    //                 //             const invitees = res[0];
    //                 //             const attendees = res[1];
    //                 //             if (invitees) {
    //                 //                 _.forOwn(invitees, (invitee, key) => {
    //                 //                     if (['Attending', 'Checked In', 'Checked Out'].includes(invitee.status)) {
    //                 //                         totalInviteeAttendee += 1;
    //                 //                     }
    //                 //                 });
    //                 //             }
    //                 //             if (appId && attendees) {
    //                 //                 totalAppAttendee = Object.keys(attendees).length;
    //                 //             }
    //                 //             if (totalAppAttendee > totalInviteeAttendee) {
    //                 //                 totalInviteeAttendee = totalAppAttendee;
    //                 //             }
    //                 //             resolve(totalInviteeAttendee);
    //                 //         });
    //                 // }
    //             });
    //     });
    // }

    // calculateSales(data: any) {
    //     return new Promise((resolve, reject) => {
    //         let netSales = 0;
    //         let grossSales = 0;
    //         let ticketSold = 0;
    //         let freeTicketCount = 0;
    //         let paidTicketCount = 0;
    //         let totalAttendee = 0;
    //         let totalGuest = 0;
    //         let totalRegistrant = 0;
    //         if (data) {
    //             _.forOwn(data, (attendee, key) => {
    //                 if (['Attending', 'Checked In', 'Checked Out'].includes(attendee.status)) {
    //                     totalAttendee += 1;
    //                     if (attendee.type === 'Guest') {
    //                         totalGuest += 1;
    //                     } else if (attendee.type === 'Registrant') {
    //                         totalRegistrant += 1;
    //                     }
    //                 }
    //             });

    //             const keys = Object.keys(data);
    //             for (const key of keys) {
    //                 if (data[key].freeRegistrant) {
    //                     if (data[key].purchasedTickets) {
    //                         // const ticketId = Object.keys(data[key].purchasedTickets)[0];
    //                         // if (ticketId) {
    //                         const assigned = Object.keys(data[key].purchasedTickets).length;
    //                         ticketSold += assigned;
    //                         freeTicketCount += assigned;
    //                         // }
    //                     }
    //                 } else {
    //                     if (data[key].purchasedTickets) {
    //                         // const ticketIds = Object.keys(data[key].purchasedTickets).length;
    //                         // if (ticketIds) {
    //                         const assigned = Object.keys(data[key].purchasedTickets).length;
    //                         ticketSold += assigned;
    //                         freeTicketCount += assigned;
    //                         // }
    //                     }
    //                     netSales += +data[key].subTotal || 0;
    //                     grossSales += +data[key].total || 0;
    //                     // if (data[key].noOfTickets) {
    //                     //     const values = data[key].noOfTickets;
    //                     //     const keys2 = Object.keys(values);
    //                     //     let currentSum = 0;
    //                     //     for (const _key of keys2) {
    //                     //         currentSum += +values[_key];
    //                     //     }
    //                     //     ticketSold += currentSum;
    //                     // }
    //                 }
    //             }
    //         }
    //         resolve({
    //             netSales,
    //             grossSales,
    //             ticketSold,
    //             freeTicketCount,
    //             paidTicketCount,
    //             totalAttendee,
    //             totalGuest,
    //             totalRegistrant,
    //         });
    //     });
    // }

    // countTickets(data: any) {
    //     return new Promise((resolve, reject) => {
    //         let totalTickets = 0;
    //         let paidTickets = false;
    //         if (data) {
    //             const keys = Object.keys(data);
    //             for (const key of keys) {
    //                 totalTickets += +data[key].availableQuantity || 0;
    //                 if (data[key].type === 'PAID') {
    //                     paidTickets = true;
    //                 }
    //             }
    //             resolve({ totalTickets, paidTickets });
    //         } else {
    //             resolve({ totalTickets, paidTickets });
    //         }
    //     });
    // }

    // getTicketsReport(customerId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         const ticketsCountRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/tickets`;
    //         this.Firebase.getByRef(ticketsCountRef)
    //             .pipe(first())
    //             .subscribe((ticketData: any) => {
    //                 this.countTickets(ticketData).then((ticketCountData: any) => {
    //                     if (ticketCountData.paidTickets) {
    //                         const ref = `customers/${customerId}/eventsData/${eventId}/tickets`;
    //                         this.Firebase.getByRef(ref)
    //                             .pipe(first())
    //                             .subscribe((ticketData: any) => {
    //                                 this.calculateSales(ticketData).then((salesData: any) => {
    //                                     resolve({ ticketReport: { ...salesData, ...ticketCountData } });
    //                                 });
    //                             });
    //                     } else {
    //                         const ref = `customers/${customerId}/eventsData/${eventId}/tickets`;
    //                         this.Firebase.getByRef(ref)
    //                             .pipe(first())
    //                             .subscribe((attendees: any) => {
    //                                 let totalAttendee = 0;
    //                                 let totalGuest = 0;
    //                                 let totalRegistrant = 0;
    //                                 if (attendees) {
    //                                     _.forOwn(attendees, (attendee, key) => {
    //                                         if (['Attending', 'Checked In', 'Checked Out'].includes(attendee.status)) {
    //                                             totalAttendee += 1;
    //                                             if (attendee.type === 'Guest') {
    //                                                 totalGuest += 1;
    //                                             } else if (attendee.type === 'Registrant') {
    //                                                 totalRegistrant += 1;
    //                                             }
    //                                         }
    //                                     });
    //                                 }
    //                                 resolve({
    //                                     ticketReport: {
    //                                         totalAttendee,
    //                                         totalGuest,
    //                                         totalRegistrant,
    //                                         ...ticketCountData,
    //                                     },
    //                                 });
    //                             });
    //                     }
    //                 });
    //             });
    //     });
    // }

    // getEventFeedbackReport(customerId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         const ticketsCountRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/feedback/submitted`;
    //         this.Firebase.getByRef(ticketsCountRef)
    //             .pipe(first())
    //             .subscribe((data: any) => {
    //                 console.log('getEventFeedbackReport() SUBMITTED DATA', data);
    //                 let feedbackReport = 0;
    //                 if (data) {
    //                     feedbackReport = Object.keys(data).length;
    //                 }
    //                 resolve({ feedbackReport });
    //             });
    //     });
    // }

    // getAppDownloadReport(appId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         const usersRef = `apps/${appId}/public/users_events`;
    //         this.Firebase.getByRefWhere(usersRef, eventId, true).then((data: any) => {
    //             let appReport = 0;
    //             if (data) {
    //                 appReport = Object.keys(data).length;
    //             }
    //             resolve({ appReport });
    //         });
    //     });
    // }

    // getDonationsReport(customerId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         const causesRef = `customers/${customerId}/eventsData/${eventId}/eventSetup/causes`;
    //         this.Firebase.getByRef(causesRef)
    //             .pipe(first())
    //             .subscribe((res) => {
    //                 if (!res) {
    //                     resolve({ causeReport: null });
    //                 } else {
    //                     let collected = 0;
    //                     let target = 0;
    //                     let totalDonations = 0;
    //                     if (res) {
    //                         _.forOwn(res, (cause: any, key) => {
    //                             totalDonations += cause.contributions ? +cause.contributions : 0;
    //                             if (cause.target) {
    //                                 target += +cause.target;
    //                             }
    //                             if (cause.collected) {
    //                                 collected += cause.collected;
    //                             }
    //                         });
    //                     }
    //                     resolve({ causeReport: { collected, target, totalDonations } });
    //                 }
    //             });
    //     });
    // }

    getCurrency(customerId: string): Promise<string> {
        const ref = `customers/${customerId}`;
        return new Promise((resolve, reject) => {
            (this.Firebase.getByRef(ref) as Observable<Customer>).pipe(take(1)).subscribe((val) => {
                console.log('val.defaultCurrency', val.defaultCurrency);
                // if()
                this.utilityService
                    .getCurrencySymbol(
                        val.defaultCurrency === 'ZA' || val.defaultCurrency === 'US'
                            ? val.defaultCurrency === 'ZA'
                                ? 'ZAR'
                                : 'USD'
                            : val.defaultCurrency
                    )
                    .then((data) => {
                        resolve(data as string);
                    });
            });
        });
    }

    getCurrencyName(customerId: string) {
        const ref = `customers/${customerId}`;
        return new Promise((resolve, reject) => {
            (this.Firebase.getByRef(ref) as Observable<Customer>).pipe(take(1)).subscribe((val) => {
                resolve(val.defaultCurrency || 'ZAR');
                // this.utilityService.getCurrencySymbol(val.defaultCurrency).then((data) => {
                //     resolve(data);
                // });
            });
        });
    }

    // updateEventCapacity(customerId: string, eventId: string, data: { eventCapacity: number }) {
    //     const ref = `customers/${customerId}/events/${eventId}`;
    //     return this.Firebase.updateByRef(ref, data);
    // }

    // _createEventTitle(title: string) {
    //     return `${title} copy ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    // }

    // addBankDetails(data: any, customerId: string, eventId: string) {
    //     return new Promise((resolve, reject) => {
    //         this.Firebase.pushByRef(`customers/${customerId}/bankDetails`, data)
    //             .then((key) => {
    //                 if (eventId) {
    //                     console.log('key', key);
    //                     const ref = `customers/${customerId}/events/${eventId}`;
    //                     this.Firebase.updateByRef(ref, {
    //                         bankId: key,
    //                     })
    //                         .then(() => {
    //                             resolve(true);
    //                         })
    //                         .catch((error) => {
    //                             console.log('error', error);
    //                             resolve(true);
    //                         });
    //                 } else {
    //                     resolve(true);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log('Error', error);
    //                 resolve(true);
    //             });
    //     });
    // }

    getEventLevelCurrency(customerId: string, eventId: string) {
        return new Promise((resolve, reject) => {
            if (this.currency) {
                resolve(this.currency);
            } else {
                if (eventId) {
                    this.Firebase.getByRef(`customers/${customerId}/events/${eventId}`)
                        .pipe(take(1))
                        .subscribe((ev: any) => {
                            if (ev.bankId) {
                                this.Firebase.getByRef(`customers/${customerId}/bankDetails/${ev.bankId}`)
                                    .pipe(take(1))
                                    .subscribe((acc: any) => {
                                        if (acc && acc.accType === 'stripe') {
                                            if (!acc.isOnBoardingCompleted) {
                                                this.stripe.getStripeAcc(acc?.accId).subscribe((accInfo: any) => {
                                                    if (accInfo?.charges_enabled || accInfo?.details_submitted) {
                                                        const prepareData = {
                                                            customerAddress:
                                                                accInfo?.business_profile?.support_address || null,
                                                            customerPhone:
                                                                accInfo?.business_profile?.support_phone || null,
                                                            customerWebSiteURL: accInfo?.business_profile?.url || null,
                                                            defaultCurrency: accInfo?.default_currency,
                                                            country: accInfo?.country,
                                                            email: accInfo?.email,
                                                            isOnBoardingCompleted: true,
                                                            businessName: accInfo?.business_profile?.name || null,
                                                        };

                                                        this.Firebase.updateByRef(
                                                            `customers/${customerId}/bankDetails/${ev.bankId}`,
                                                            prepareData
                                                        )
                                                            .then(() => {
                                                                this.checkCustomerAndEventLevelCurrency(
                                                                    customerId,
                                                                    ev
                                                                ).then((resp: any) => {
                                                                    this.currency = resp;
                                                                    resp.bankAcc = acc;
                                                                    this.getConvertedServiceFee(resp).then(
                                                                        (res: any) => {
                                                                            this.getTicketFeePercentage(
                                                                                ev.app_id || null,
                                                                                customerId,
                                                                                eventId
                                                                            ).then((serviceFeePercentage: any) => {
                                                                                res.serviceFeePercentage =
                                                                                    serviceFeePercentage;
                                                                                resolve(res);
                                                                            });
                                                                        }
                                                                    );
                                                                });
                                                            })
                                                            .catch((error) => {
                                                                console.log('Error', error);
                                                            });
                                                    } else {
                                                        this.checkCustomerAndEventLevelCurrency(customerId, ev).then(
                                                            (resp: any) => {
                                                                this.currency = resp;
                                                                resp.bankAcc = acc;
                                                                this.getConvertedServiceFee(resp).then((res: any) => {
                                                                    this.getTicketFeePercentage(
                                                                        ev.app_id || null,
                                                                        customerId,
                                                                        eventId
                                                                    ).then((serviceFeePercentage: any) => {
                                                                        res.serviceFeePercentage = serviceFeePercentage;
                                                                        resolve(res);
                                                                    });
                                                                });
                                                            }
                                                        );
                                                    }
                                                });
                                            } else {
                                                this.checkCustomerAndEventLevelCurrency(customerId, ev).then(
                                                    (resp: any) => {
                                                        this.currency = resp;
                                                        resp.bankAcc = acc;
                                                        this.getConvertedServiceFee(resp).then((res: any) => {
                                                            this.getTicketFeePercentage(
                                                                ev.app_id || null,
                                                                customerId,
                                                                eventId
                                                            ).then((serviceFeePercentage: any) => {
                                                                res.serviceFeePercentage = serviceFeePercentage;
                                                                resolve(res);
                                                            });
                                                        });
                                                    }
                                                );
                                            }
                                        } else {
                                            this.currency = {
                                                name: 'South African Rand',
                                                code: 'ZAR',
                                                symbol: 'R',
                                            };

                                            this.getConvertedServiceFee(this.currency).then((res: any) => {
                                                this.getTicketFeePercentage(
                                                    ev.app_id || null,
                                                    customerId,
                                                    eventId
                                                ).then((serviceFeePercentage: any) => {
                                                    res.serviceFeePercentage = serviceFeePercentage;
                                                    resolve(res);
                                                });
                                            });
                                            // resolve({
                                            //     name: 'South African Rand',
                                            //     code: 'ZAR',
                                            //     symbol: 'R',
                                            // });
                                        }
                                    });
                            } else {
                                this.checkCustomerAndEventLevelCurrency(customerId, ev).then((resp: any) => {
                                    this.currency = resp;
                                    this.getConvertedServiceFee(resp).then((res: any) => {
                                        this.getTicketFeePercentage(ev.app_id || null, customerId, eventId).then(
                                            (serviceFeePercentage: any) => {
                                                res.serviceFeePercentage = serviceFeePercentage;
                                                resolve(res);
                                            }
                                        );
                                    });
                                    // resolve(resp);
                                });
                            }
                        });
                }
            }
        });
    }

    getConvertedServiceFee(currencyData: any) {
        console.log('=====currencyData', currencyData);
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`pricing/${currencyData.code}`)
                .pipe(take(1))
                .subscribe((price: any) => {
                    if (price) {
                        if (price.override && !isNaN(price.override)) {
                            console.log('Override Currency', price.override);
                            currencyData.lowestPriceValue = +price.override;
                            currencyData.actualPrice = +price.override / 100;
                            currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                            resolve(currencyData);
                        } else if (Number(price.multiple) === 1) {
                            // console.log('Multiple of 1');
                            // currencyData.lowestPriceValue = 1 * 100;
                            // currencyData.actualPrice = 1;
                            // currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                            this.getCurrencyChargesBaseOnCountry(currencyData).then((resp: any) => {
                                if (resp && resp.quotes) {
                                    console.log('Response From the currency API LAYER', price);
                                    currencyData.lowestPriceValue = (
                                        +resp.quotes[`USD${currencyData.code}`] * 100
                                    ).toFixed(0);
                                    currencyData.actualPrice = +(+resp.quotes[`USD${currencyData.code}`]).toFixed(2);
                                    currencyData.zeroDecimal = resp.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                } else {
                                    currencyData.lowestPriceValue = +price.override;
                                    currencyData.actualPrice = +price.override / 100;
                                    currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                }
                            });
                            // resolve(currencyData);
                        } else {
                            this.getCurrencyChargesBaseOnCountry(currencyData).then((resp: any) => {
                                if (resp && resp.quotes) {
                                    console.log('Response From the currency API LAYER', price);
                                    currencyData.lowestPriceValue = (
                                        +resp.quotes[`USD${currencyData.code}`] * 200
                                    ).toFixed(0);
                                    currencyData.actualPrice = +(+resp.quotes[`USD${currencyData.code}`] * 2).toFixed(
                                        2
                                    );
                                    currencyData.zeroDecimal = resp.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                } else {
                                    currencyData.lowestPriceValue = +price.override;
                                    currencyData.actualPrice = +price.override / 100;
                                    currencyData.zeroDecimal = price.zeroDecimal ? true : false;
                                    resolve(currencyData);
                                }
                            });
                        }
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    // GET PLAN AND DECIDE TICKET FEE PERCENTAGE
    getTicketFeePercentage(appId = null, customerId: string, eventId: string) {
        let planFees: any = {
            'enterprise agreement': 3,
            business: 3.5,
            'event pro': 6,
            expert: 3,
            solo: 7,
            pro: 5,
        };
        return new Promise((resolve, reject) => {
            combineLatest([
                // this.Firebase.getByRef(`pricePlan`),
                this.stripe.getProducts(),
            ])
                .pipe(take(1))
                .subscribe((values: any) => {
                    const plans = values[0] || [];
                    this.checkEventPlanAndSubscription(appId, eventId, customerId).then((resp: any) => {
                        console.log('====resp', resp);
                        // console.log('====plan', plans);
                        if (
                            !resp ||
                            (resp?.status !== 'active' && resp?.status !== 'trialing') ||
                            resp.needToRedirectCustomerPortal
                        ) {
                            resolve(planFees['solo']);
                        } else {
                            const planId = resp?.plan?.product;
                            console.log('=====', planId);
                            const plan: any = plans.find((p: any) => p.id === planId)?.name?.toLowerCase();
                            // console.log('=====plan', plan);
                            if (plan) {
                                resolve(
                                    planFees[
                                        plan?.toLowerCase()?.includes('enterprise') ? 'enterprise agreement' : plan
                                    ]
                                );
                            } else {
                                resolve(planFees['solo']);
                            }
                        }
                    });
                });
        });
    }

    checkEventPlanAndSubscription(appId: string | null, eventId: string, customerId: string) {
        return new Promise((resolve, reject) => {
            // if (appId) {
            this.Firebase.getByRef(`customers/${customerId}/subscriptions`)
                .pipe(take(1))
                .subscribe((plans: any) => {
                    if (plans) {
                        const checkIfBussinessOREnterPricePlanAvailabled = _.keys(plans).filter(
                            (id: string) =>
                                plans[id]?.plan?.toLowerCase() === 'business' ||
                                plans[id]?.plan?.toLowerCase()?.includes('enterprise')
                        );

                        const checkIfSoloPlan = _.keys(plans).filter(
                            (id: string) => plans[id]?.plan?.toLowerCase() === 'solo'
                        )?.[0];

                        const checkProPlanSubscription = _.keys(plans).filter(
                            (id: string) => plans[id]?.plan?.toLowerCase() === 'pro'
                        )?.[0];

                        if (appId === 'HELLOCROWD' || !appId) {
                            const sub$: any[] = [];

                            if (checkIfBussinessOREnterPricePlanAvailabled.length) {
                                checkIfBussinessOREnterPricePlanAvailabled.forEach((planId: string) => {
                                    if (plans?.[planId]?.subscriptionId) {
                                        const subId = plans[planId].subscriptionId;
                                        sub$.push(this.stripe.getSubData(subId));
                                    }
                                });

                                combineLatest(sub$)
                                    .pipe(take(1))
                                    .subscribe((subscriptions: any) => {
                                        const activeSubscription = subscriptions?.filter(
                                            (sub: any) => sub.status === 'active' || sub?.status === 'trialing'
                                        )?.[0];

                                        activeSubscription
                                            ? resolve(activeSubscription)
                                            : resolve({ needToRedirectCustomerPortal: true });
                                    });
                            } else {
                                // const checkProPlanSubscription = _.keys(plans).filter(
                                //     (id: string) => plans[id]?.appId === 'HELLOCROWD'
                                // )?.[0];

                                if (checkProPlanSubscription) {
                                    const subId = plans[checkProPlanSubscription].subscriptionId;
                                    this.stripe
                                        .getSubData(subId)
                                        .pipe(take(1))
                                        .subscribe((subscription: any) => {
                                            resolve(subscription);
                                        });
                                } else {
                                    if (appId === 'HELLOCROWD') {
                                        resolve({
                                            needToRedirectCustomerPortal: true,
                                        });
                                    } else {
                                        // const checkIfSoloPlan = _.keys(plans).filter(
                                        //     (id: string) => plans[id]?.appId === null
                                        // )?.[0];

                                        if (checkIfSoloPlan) {
                                            const subId = plans[checkIfSoloPlan].subscriptionId;
                                            this.stripe
                                                .getSubData(subId)
                                                .pipe(take(1))
                                                .subscribe((subscription: any) => {
                                                    resolve(subscription);
                                                });
                                        } else {
                                            resolve({
                                                needToRedirectCustomerPortal: true,
                                            });
                                        }
                                    }
                                }
                            }
                        } else {
                            const planId = _.keys(plans).filter(
                                (id: string) =>
                                    plans[id]?.plan?.toLowerCase() === 'business' ||
                                    plans[id]?.plan?.toLowerCase()?.includes('enterprise')
                            )[0];
                            if (plans?.[planId]?.subscriptionId) {
                                const subId = plans[planId].subscriptionId;
                                this.stripe
                                    .getSubData(subId)
                                    .pipe(take(1))
                                    .subscribe((subscription: any) => {
                                        resolve(subscription);
                                    });
                            } else {
                                resolve({
                                    needToRedirectCustomerPortal: true,
                                });
                            }
                        }
                    } else {
                        resolve(null);
                    }
                });
            // this.firebase.getByRefWhere(`customers/${customerId}/subscriptions`, 'appId', appId).then((plan: any) => {
            //     if (plan) {
            //         const planId = _.keys(plan)[0];
            //         console.log('PLANID', planId);

            //         if (plan[planId]?.subscriptionId) {
            //             this.stripe
            //                 .getSubData(plan[planId].subscriptionId)
            //                 .pipe(take(1))
            //                 .subscribe((subscription: any) => {
            //                     resolve(subscription);
            //                 });
            //         } else {
            //             resolve(null);
            //         }
            //     } else {
            //         resolve(null);
            //     }
            // });
        });
    }

    setPricePlan() {
        const prepareData = {
            flex: {
                HelloCrowdPrice: 499,
                customBrandedAppPrice: 1499,
                serviceFeePercentage: 6,
            },
            pro: {
                HelloCrowdPrice: 199,
                customBrandedAppPrice: 499,
                serviceFeePercentage: 3,
            },
            enterprise: {
                serviceFeePercentage: 2.5,
            },
        };

        return new Promise((resolve, reejct) => {
            this.Firebase.updateByRef(`pricePlan`, prepareData);
        });
    }

    getCurrencyChargesBaseOnCountry(currencyData: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${environment.fcfApiUrl}/v2api/get-converted-currency`, { currency: currencyData.code })
                .subscribe((price: any) => {
                    console.log('===price', price);
                    resolve(price);
                });
            // this.http
            //     .get(
            //         `${environment.currencyEndPoint}?access_key=${environment.currencyAccessKey}&currencies=${currencyData.code}&source=USD`,
            //         {
            //             headers: {
            //                 'Access-Control-Allow-Headers':
            //                     'Content-Type, Authorization, Content-Length, X-Requested-With',
            //                 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            //                 'Access-Control-Allow-Origin': '*',
            //             },
            //         }
            //     )
            //     .subscribe((price: any) => {
            //         resolve(price);
            //         // if (price) {
            //         //     console.log('Response From the currency API LAYER', price);
            //         //     currencyData.lowestPriceValue = (+price.quotes[`USD${currencyData.code}`] * 100).toFixed(0);
            //         //     currencyData.actualPrice = +price.quotes[`USD${currencyData.code}`];
            //         //     currencyData.zeroDecimal = price.zeroDecimal ? true : false;
            //         //     resolve(currencyData);
            //         // }
            //     });
        });
    }

    checkCustomerAndEventLevelCurrency(customerId: any, ev: any) {
        return new Promise((resolve, reject) => {
            if (ev.currency) {
                console.log('EVENT LEVEL CURRENCY');

                // const regex =
                //     /[A-Za-z]f[A-Za-z][A-Za-z][A-Za-z][A-Za-z]\s[A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z][A-Za-z]\s\([A-Za-z][A-Za-z][A-Za-z]\)/i;
                // console.log(regex.test(ev.currency));
                // if (regex.test(ev.currency)) {
                //     const currency =
                //         ev.currency
                //             .replace(/[{()}]/g, '')
                //             .split(' ')
                //             .pop()
                //             .toLowerCase() || 'usd';

                //     this.utilityService.getCurrencySymbol(currency, false).then((currency: any) => {
                //         console.log('currency', currency);
                //         // this.currency = currency;
                //         resolve(currency);
                //     });
                // } else {
                // }
                // resolve(ev.cu)
                this.utilityService.getCurrencySymbol(ev.currency, false).then((currency: any) => {
                    console.log('currency', currency);
                    // this.currency = currency;
                    resolve(currency);
                });
            } else {
                console.log('NEED TO CHECK CUSTOMER LEVEL CURRENCY');
                this.Firebase.getByRef(`customers/${customerId}`)
                    .pipe(take(1))
                    .subscribe((customer: any) => {
                        console.log('DEFAULR CURRENCY ====', customer.defaultCurrency);
                        if (customer.defaultCurrency) {
                            if (customer.defaultCurrency === 'ZA' || customer.defaultCurrency === 'US') {
                                // this.currency = {
                                //     name: 'South African Rand',
                                //     code: 'ZAR',
                                //     symbol: 'R',
                                // };

                                if (customer.defaultCurrency === 'ZA') {
                                    resolve({
                                        name: 'South African Rand',
                                        code: 'ZAR',
                                        symbol: 'R',
                                    });
                                } else {
                                    resolve({
                                        name: 'United States dollar',
                                        code: 'USD',
                                        symbol: '$',
                                    });
                                }
                            } else {
                                this.utilityService
                                    .getCurrencySymbol(customer.defaultCurrency, false)
                                    .then((currency: any) => {
                                        console.log('currency', currency);
                                        // this.currency = currency;
                                        resolve(currency);
                                    });
                            }
                        } else {
                            this.utilityService.getCurrencySymbol('USD', false).then((currency: any) => {
                                console.log('currency', currency);
                                resolve(currency);
                            });
                        }
                    });
            }
        });
    }

    getCustomerLogo(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyLogo`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val || '');
                });
        });
    }

    getCustomerLogoDark(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyLogoDark`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val || '');
                });
        });
    }
    getCustomerAddress(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/address`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val || '');
                });
        });
    }

    getCustomerCountry(customerId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/defaultCountry`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val);
                });
        });
    }

    getCustomerCompany(customerId: string) {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/companyName`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val);
                });
        });
    }

    getCustomerTimeZone(customerId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.Firebase.getByRef(`customers/${customerId}/defaultTimeZone`)
                .pipe(first())
                .subscribe((val) => {
                    resolve(val as string);
                });
        });
    }

    getCustomerLevelCurrency(customerId: string) {
        return this.Firebase.getByRef(`customers/${customerId}/defaultCurrency`).pipe(
            take(1),
            switchMap((currency: any): any => {
                if (currency) {
                    if (currency === 'ZA' || currency === 'US') {
                        if (currency === 'ZA') {
                            return of({
                                name: 'South African Rand',
                                code: 'ZAR',
                                symbol: 'R',
                            });
                        } else {
                            return of({
                                name: 'United States dollar',
                                code: 'USD',
                                symbol: '$',
                            });
                        }
                    } else {
                        return from(
                            this.utilityService.getCurrencySymbol(currency, false).then((currency: any) => {
                                return currency;
                            })
                        );
                    }
                } else {
                    return from(
                        this.utilityService.getCurrencySymbol('USD', false).then((currency: any) => {
                            return currency;
                        })
                    );
                }
            })
        );
    }

    // getAllEloquaData(customerId: string) {
    //     // const elouqasData: any = [];
    //     return this.Firebase.getByRef(`customers/${customerId}/integrations/eloquasData`).pipe(
    //         map((resp: any) => {
    //             const elouqasData: any = [];
    //             if (resp) {
    //                 const keys = _.keys(resp);

    //                 keys.forEach((k: string) => {
    //                     // console.log('k', k);
    //                     resp[k].id = k;
    //                     if (!resp[k].title) {
    //                         resp[k].title = 'Oracle Eloqua';

    //                         elouqasData.push(resp[k]);
    //                     } else {
    //                         elouqasData.push(resp[k]);
    //                     }
    //                 });
    //                 return elouqasData;
    //             } else {
    //                 return [];
    //             }
    //         })
    //     );
    // }

    // getEloquaDataById(customerId: string, id: any) {
    //     const eloquaId = id ? id : '';
    //     console.log('======', eloquaId);
    //     if (id) {
    //         return this.Firebase.getByRef(`customers/${customerId}/integrations/eloquasData/${eloquaId}`).pipe(
    //             // take(1),
    //             map((resp: any) => {
    //                 if (resp) {
    //                     resp.id = id;
    //                     return resp;
    //                 } else {
    //                     return null;
    //                 }
    //             })
    //         );
    //     } else {
    //         return of(null);
    //     }
    // }

    // generateEloquaId(customerId: string) {
    //     return new Promise((resolve) => {
    //         const ref = this.db.database.ref();
    //         const newRef = ref.child(`customers/${customerId}/integrations/eloquasData`).push();
    //         const key = newRef.key as string;
    //         resolve(key);
    //         // this.db.database
    //         //     .ref()
    //         //     .child(`customers/${customerId}/integrations/eloquasData/${key}`)
    //         //     .once('value', (data: any) => {
    //         //         if (data.val()) {
    //         //             resolve(data.val());
    //         //         } else {
    //         //             resolve(key);
    //         //         }
    //         //     });
    //     });
    // }

    // getEloquaById(customerId: string, id: string) {
    //     console.log('getEloqua Id Called');
    //     this.db.database
    //         .ref()
    //         .child(`customers/${customerId}/integrations/eloquasData/${id}`)
    //         .on('value', (data: any) => {
    //             this.$connectedEloqua.next(data.val() || false);
    //         });
    // }

    // disConnectEloquaRef(customerId: string, id: string) {
    //     this.db.database.ref().child(`customers/${customerId}/integrations/eloquasData/${id}`).off();
    // }

    // checkIfBankAccountAdded(customerId: string, eventId: string, isCheckForPageMenu = false) {
    //     return combineLatest([
    //         this.Firebase.getByRef(`/customers/${customerId}/eventsData/${eventId}/eventSetup/tickets`),
    //         this.Firebase.getByRef(`/customers/${customerId}/eventsData/${eventId}/eventSetup/causes`),
    //         this.getEvent(customerId, eventId),
    //     ]).pipe(
    //         take(1),
    //         switchMap((values: any): any => {
    //             const ticketKeys = _.keys(values[0]);
    //             const checkIfPaidORDonationTicketAvailabel = ticketKeys.some(
    //                 (k: any) => values[0][k].type === 'PAID' || values[0][k].type === 'DONATION'
    //             );
    //             if (!isCheckForPageMenu) {
    //                 if (checkIfPaidORDonationTicketAvailabel || values[1]) {
    //                     if (!values[2]?.bankId) return of(false);
    //                     else {
    //                         return (
    //                             this.Firebase.getByRef(
    //                                 `/customers/${customerId}/bankDetails/${values[2]?.bankId}`
    //                             ) as Observable<any>
    //                         ).pipe(
    //                             map((backAcc): any => {
    //                                 if (backAcc?.accType === 'stripe') {
    //                                     return !backAcc?.isOnBoardingCompleted ? false : true;
    //                                 } else {
    //                                     // console.log('PAY STACK ACCOUNT');
    //                                     return true;
    //                                 }
    //                             })
    //                         );
    //                     }
    //                 } else {
    //                     return of(true);
    //                 }
    //             } else {
    //                 if (checkIfPaidORDonationTicketAvailabel || values[1]) {
    //                     return of(true);
    //                 } else {
    //                     return of(false);
    //                 }
    //             }
    //         })
    //     );
    // }

    // checkIsUserOwner() {
    //     return new Promise((resolve, reject) => {
    //         if (this.isOwner !== undefined) {
    //             resolve(this.isOwner);
    //         }
    //         if (!this.userId) this.userId = localStorage.getItem('user_id') || '';
    //         if (!this.customerId) {
    //             this.customerId = localStorage.getItem('customer_id') || '';
    //         }
    //         const ref = `customers/${this.customerId}/teamMembers`;
    //         this.Firebase.getByRef(ref)
    //             .pipe(first())
    //             .subscribe((data: any) => {
    //                 if (data) {
    //                     this.isOwner = data[this.userId] ? data[this.userId].toLowerCase() === 'owner' : false;
    //                     resolve(this.isOwner);
    //                 } else {
    //                     this.isOwner = false;
    //                     resolve(this.isOwner);
    //                 }
    //             });
    //     });
    // }

    // getAllActiveBanks(customerId = '') {
    //     return new Promise((resolve, reject) => {
    //         const ref = `customers/${this.customerId || customerId}/bankDetails`;
    //         this.Firebase.getByRef(ref)
    //             .pipe(first())
    //             .subscribe((data: any) => {
    //                 const retArr: any = [];
    //                 if (data) {
    //                     _.forOwn(data, (bank, key) => {
    //                         if (bank.accType === 'stripe') {
    //                             if (bank.isOnBoardingCompleted) {
    //                                 bank.currency = bank.defaultCurrency;
    //                                 retArr.push({ ...bank, id: key });
    //                             }
    //                         } else {
    //                             retArr.push({ ...bank, id: key });
    //                         }
    //                     });
    //                 }
    //                 resolve(retArr);
    //             });
    //     });
    // }

    // getBankDetails(customerId = '') {
    //     const ref = `customers/${customerId || this.customerId}/bankDetails`;
    //     return this.Firebase.getByRef(ref);
    // }

    // validAccountToDelete(bankId: string) {
    //     return new Promise((resolve, reject) => {
    //         const ref = `customers/${this.customerId}/events`;
    //         this.Firebase.getByRefWhere(ref, 'bankId', bankId).then((data: any) => {
    //             if (!data) {
    //                 resolve(true);
    //             } else {
    //                 let canDelete = true;
    //                 _.forOwn(data, (event, eventId) => {
    //                     console.log('EVENT END DATE VALUEOF', moment(event.end_date_time_unix).valueOf());
    //                     console.log('CURRENT DATE VALUEOF', moment().valueOf());
    //                     console.log('DIFFENRECE', moment(event.end_date_time_unix).diff(moment().valueOf()));

    //                     if (moment(event.end_date_time_unix).diff(moment().valueOf()) > 0) {
    //                         canDelete = false;
    //                     }
    //                 });
    //                 resolve(canDelete);
    //             }
    //         });
    //     });
    // }

    // checkHaveRecievedPaymenets(eventId: string): Promise<boolean> {
    //     const donationsRef = `customers/${this.customerId}/eventsData/${eventId}/donations`;
    //     const transactionsRef = `customers/${this.customerId}/eventsData/${eventId}/transactions`;
    //     return new Promise((resolve, reject) => {
    //         combineLatest([this.Firebase.getByRef(donationsRef), this.Firebase.getByRef(transactionsRef)])
    //             .pipe(first())
    //             .subscribe((res) => {
    //                 if (res[0] || res[1]) {
    //                     resolve(true);
    //                 } else {
    //                     resolve(false);
    //                 }
    //             });
    //     });
    // }

    prepareBrandKitData(data: any) {
        return {
            colourScheme: data.colourScheme || '#cccccc',
            // colourSchemeSecondary: data.colourScheme || '#00b4ce',
            icon: data.icon || null,
            iconSmall: data.iconSmall || null,
            splashScreen: data.splashScreen || null,
            splashScreenSmall: data.splashScreenSmall || null,
            artworksType: data.artworksType || 'standard',
            uploadedArtwork: data.uploadedArtwork || null,
            uploadedArtworkTransparent: data.uploadedArtworkTransparent || null,
            colours: data.colours || null,
            style: data.style || null,
            headerFonts: data.headerFonts || null,
            bodyFonts: data.bodyFonts || null,
            lightCustomerLogo: data.lightCustomerLogo || null,
            darkCustomerLogo: data.darkCustomerLogo || null,
            headerFontFile: data.headerFontFile || null,
            bodyFontFile: data.bodyFontFile || null,
        };
    }

    getBrandKitData() {
        const customerId = localStorage.getItem('customer_id') || this.Cookies.get('customer_id');
        const listRef = `customers/${customerId}/brandKit`;
        return this.Firebase.getByRef(listRef) as Observable<BrandKit>;
    }

    // getBasePlanSubscriptions() {
    //     return new Promise((resolve) => {
    //         this.Firebase.getByRefWhere(`customers/${this.customerId}/subscriptions`, 'appId', null).then(
    //             (suRes: any) => {
    //                 if (suRes) {
    //                     const sub = _.values(suRes)[0];
    //                     this.stripe
    //                         .getSubData(sub.subscriptionId)
    //                         .pipe(take(1))
    //                         .subscribe((res: any) => {
    //                             if (res.status === 'active' || res?.status === 'trialing') {
    //                                 resolve({
    //                                     data: sub,
    //                                     status: 'ACTIVE',
    //                                 });
    //                             } else {
    //                                 resolve({
    //                                     data: sub,
    //                                     status: 'INACTIVE',
    //                                 });
    //                             }
    //                         });
    //                 } else {
    //                     resolve({
    //                         status: 'UNSUBSCRIBED',
    //                     });
    //                 }
    //             }
    //         );
    //     });
    // }

    checkIfEventIsSoldOutORNot(customerId: string, eventId: string, formId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            combineLatest([
                this.Firebase.getByRef(`customers/${customerId}/events/${eventId}/eventCapacity`),
                this.Firebase.getByRef(
                    `customers/${customerId}/eventsData/${eventId}/eventSetup/registration/${formId}/availableTickets`
                ),
                this.Firebase.getByRef(`customers/${customerId}/eventsData/${eventId}/tickets`),
            ])
                .pipe(take(1))
                .subscribe((values: any) => {
                    const eventCapacity = values[0];
                    const formTicketsId = _.keys(values[1]);
                    let totalInvitees = 0;

                    _.forOwn(values[2], (invitee, key) => {
                        if (invitee.status === 'Attending' || invitee.status === 'Checked In') {
                            totalInvitees += 1;
                        }
                    });
                    // if (eventCapacity === undefined || eventCapacity === null) {
                    const ticket$: any[] = [];
                    formTicketsId.forEach((id) => {
                        ticket$.push(
                            this.Firebase.getByRef(
                                `customers/${customerId}/eventsData/${eventId}/eventSetup/tickets/${id}`
                            )
                        );
                    });

                    combineLatest(ticket$)
                        .pipe(take(1))
                        .subscribe((tickets: any) => {
                            tickets = tickets.filter((ticket: any) => ticket !== null);
                            const checkIfFreeTicketAvilable = tickets.some((ticket: any) => ticket.type === 'FREE');
                            if (tickets.length === 1 && checkIfFreeTicketAvilable) {
                                if (eventCapacity && eventCapacity <= totalInvitees) {
                                    resolve(true);
                                } else {
                                    const allSoldOut = tickets.every(
                                        (ticket: any) => ticket.availableQuantity && Number(ticket.remaining) < 1
                                    );
                                    if (allSoldOut) {
                                        resolve(true);
                                    } else {
                                        const allNotOnSale = tickets.every(
                                            (ticket: any) => ticket.startWhen === 'Not on sale'
                                        );
                                        if (allNotOnSale) {
                                            resolve(true);
                                        } else {
                                            var currentDate = moment();
                                            // Define the start date and end date
                                            const allWithinPeriod = tickets.every((ticket: any) => {
                                                var startDate = moment(ticket.startDateTimeUnix); // Example start date
                                                var endDate = moment(ticket.endDateTimeUnix);
                                                return currentDate.isBetween(startDate, endDate);
                                            });
                                            if (!allWithinPeriod) {
                                                resolve(true);
                                            } else {
                                                resolve(false);
                                            }
                                        }
                                    }
                                }
                            } else {
                                const checkIfRemainingTicketAvailable = tickets.some(
                                    (ticket: any) => !ticket.availableQuantity || Number(ticket.remaining) > 0
                                );

                                if (checkIfRemainingTicketAvailable) {
                                    if (eventCapacity && eventCapacity <= totalInvitees) {
                                        resolve(true);
                                    } else {
                                        const someOnSale = tickets.some(
                                            (ticket: any) => ticket.startWhen !== 'Not on sale'
                                        );
                                        if (!someOnSale) {
                                            resolve(true);
                                        } else {
                                            var currentDate = moment();
                                            // Define the start date and end date
                                            const someWithPeriod = tickets.some((ticket: any) => {
                                                var startDate = moment(ticket.startDateTimeUnix); // Example start date
                                                var endDate = moment(ticket.endDateTimeUnix);
                                                return currentDate.isBetween(startDate, endDate);
                                            });
                                            if (!someWithPeriod) {
                                                resolve(true);
                                            } else {
                                                resolve(false);
                                            }
                                        }
                                    }
                                } else {
                                    resolve(true);
                                }
                            }
                        });
                });
        });
    }
}
