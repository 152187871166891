import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { EventNotAvailableComponent } from './event-not-available/event-not-available.component';

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {
                path: 'deep-link',
                loadChildren: () => import('./deep-link/deep-link.module').then((m) => m.DeepLinkingModule),
            },
            {
                path: 'wake',
                loadChildren: () => import('./test/test.module').then((m) => m.TestModule),
            },
            {
                path: ':eventId',
                children: [
                    {
                        path: 'event-feedback',
                        loadChildren: () =>
                            import('./event-feedback/event-feedback.module').then((m) => m.EventFeedbackModule),
                    },

                    {
                        path: 'tickets',
                        loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsModule),
                    },
                    {
                        path: 'feedback-report',
                        loadChildren: () =>
                            import('./feedback-report/feedback-report.module').then((m) => m.FeedbackReportModule),
                    },
                    {
                        path: 'promote',
                        loadChildren: () => import('./promote/promote.module').then((m) => m.PromoteModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'interactivity',
                        loadChildren: () =>
                            import('./live-polling/live-polling.module').then((m) => m.LivePollingModule),
                    },
                    {
                        path: 'live-feeds',
                        loadChildren: () => import('./live-feeds/live-feeds.module').then((m) => m.LiveFeedsModule),
                    },
                    {
                        path: 'donate/:causeId',
                        loadChildren: () => import('./donate/donate.module').then((m) => m.DonateModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'abstracts',
                        loadChildren: () => import('./abstracts/abstracts.module').then((m) => m.AbstractsModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'speakers',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'sponsors',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'exhibitors',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'event-not-available',
                        component: EventNotAvailableComponent,
                    },
                    {
                        path: ':landingPageId',
                        loadChildren: () =>
                            import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'add-to-calendar',
                        loadChildren: () =>
                            import('./event-calendar-link/event-calendar-link.module').then(
                                (m) => m.EventCalendarLinkModule
                            ),
                        data: {
                            revalidate: 20,
                        },
                    },
                ],
            },
            {
                path: ':customerId/:eventId',
                children: [
                    {
                        path: 'event-feedback',
                        loadChildren: () =>
                            import('./event-feedback/event-feedback.module').then((m) => m.EventFeedbackModule),
                    },
                    {
                        path: 'add-to-calendar',
                        loadChildren: () =>
                            import('./event-calendar-link/event-calendar-link.module').then(
                                (m) => m.EventCalendarLinkModule
                            ),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'tickets',
                        loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsModule),
                    },
                    {
                        path: 'feedback-report',
                        loadChildren: () =>
                            import('./feedback-report/feedback-report.module').then((m) => m.FeedbackReportModule),
                    },
                    {
                        path: 'promote',
                        loadChildren: () => import('./promote/promote.module').then((m) => m.PromoteModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'interactivity',
                        loadChildren: () =>
                            import('./live-polling/live-polling.module').then((m) => m.LivePollingModule),
                    },
                    {
                        path: 'live-feeds',
                        loadChildren: () => import('./live-feeds/live-feeds.module').then((m) => m.LiveFeedsModule),
                    },
                    {
                        path: 'donate/:causeId',
                        loadChildren: () => import('./donate/donate.module').then((m) => m.DonateModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'abstracts',
                        loadChildren: () => import('./abstracts/abstracts.module').then((m) => m.AbstractsModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'speakers',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'sponsors',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'exhibitors',
                        loadChildren: () => import('./profile-setup/profile.module').then((m) => m.ProfileSetupModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                    {
                        path: 'event-not-available',
                        component: EventNotAvailableComponent,
                    },
                    {
                        path: ':landingPageId',
                        loadChildren: () =>
                            import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
                        data: {
                            revalidate: 20,
                        },
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
